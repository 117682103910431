<template>
  <div>
    <!-- Navbar Start -->
    <nav class="navbar navbar-landing shadow navbar-expand-md fixed-top">
      <div class="container justify-content-between">
        <!-- Brand -->
        <router-link class="navbar-brand d-none d-lg-inline-block" to="/"
          ><img src="@/assets/img/logo.png" alt="" width="40" class="mr-3" />
          PSB Nurul Jadid</router-link
        >
        <router-link class="navbar-brand d-lg-none" to="/">PSBNJ</router-link>

        <!-- Navbar links -->
        <div>
          <button
            type="button"
            class="btn btn-1 mr-3"
            @click="$router.push('/register')"
          >
            Daftar
          </button>
          <button
            type="button"
            class="btn btn-2"
            @click="$router.push('/login')"
          >
            Login
          </button>
        </div>
      </div>
    </nav>
    <!-- Navbar End -->

    <!-- Home Section Start -->
    <section class="home row d-flex align-items-center">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 text-center">
            <div class="home-img">
              <div class=""></div>
              <img src="@/assets/img/logonj.png" alt="App" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="home-text">
              <h1 class="title">Pendaftaran Santri Baru</h1>
              <h3 class="text-light">Pondok Pesantren Nurul Jadid</h3>
              <p class="mb-2">
                Nurul Jadid Boarding School for Education and Science
              </p>
              <p class="font-weight-bold">Tahun Ajaran 2025 - 2026</p>

              <div class="home-btn">
                <router-link to="/register" class="btn btn-1"
                  >Daftar Sekarang</router-link
                >
                <!-- <a href="https://www.nuruljadid.net/11040/brochure-2022" class="btn btn-1 video-play-btn" target="_blank"
                  data-toggle="tooltip" data-placement="top" title="Download Brosur">
                  <i class="fas fa-download"></i>
                  <fa-icon :icon="['fas', 'download']"></fa-icon>
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Home Section End -->

    <!-- How it Works Section Start -->
    <section class="how-it-works section-padding">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="section-title">
              <h2><span>Alur</span> Pendaftaran Online</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg col-md-6">
            <div class="how-it-works-item line-right">
              <div class="step">1</div>
              <h6>Pembuatan Akun</h6>
              <hr />
              <p>
                Mengisi identitas calon peserta didik sekaligus pembuatan akun
                untuk mendapatkan Nomor Registrasi.
              </p>
            </div>
          </div>
          <div class="col-lg col-md-6">
            <div class="how-it-works-item line-right">
              <div class="step">2</div>
              <h6>Login & Melengkapi Data</h6>
              <hr />
              <p>
                Melengkapi data peserta didik, data orang tua / wali atau mahrom
                khususnya santri putri.
              </p>
            </div>
          </div>
          <div class="col-lg col-md-6">
            <div class="how-it-works-item line-right">
              <div class="step">3</div>
              <h6>Mengunggah Berkas</h6>
              <hr />
              <p>
                Mengunggah berkas persyaratan dan berkas pendukung lainnya yang
                berupa gambar / foto.
              </p>
            </div>
          </div>
          <div class="col-lg col-md-6">
            <div class="how-it-works-item line-right">
              <div class="step">4</div>
              <h6>Pembayaran</h6>
              <hr />
              <p>
                Melakukan pembayaran biaya pendaftaran sesuai pendidikan yang
                telah dipilih.
              </p>
            </div>
          </div>
          <div class="col-lg col-md-6">
            <div class="how-it-works-item">
              <div class="step">5</div>
              <h6>Cetak Pendaftaran</h6>
              <hr />
              <p>
                Cetak atau simpan Nomor Registrasi sebagai bukti pendaftaran
                untuk ditunjukkan ke petugas PSB.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- How it Works Section End -->

    <!-- SECTION Syarat Pendaftaran Ulang -->
    <section id="sec-pricing">
      <div class="container">
        <!-- Bottom -->
        <div id="bottom">
          <div class="row justify-content-between align-items-center">
            <div class="col-12 col-md-9 col-lg-6 mb-4 mb-lg-0">
              <div class="section-title-side">
                <h2 class="mb-3 mb-md-4"><span>Syarat</span> Pendaftaran</h2>
              </div>
              <p class="mb-3 mb-lg-5">
                Untuk memenuhi persyaratan pendaftaran santri baru, perlu
                beberapa berkas<br />yang harus disiapkan:
              </p>
              <ul>
                <li>
                  <img src="@/assets/img/icon-check.png" alt="" />Photo Copy
                  Akta Kelahiran Peserta Didik
                </li>
                <li>
                  <img src="@/assets/img/icon-check.png" alt="" />
                  <div>
                    Photo Copy KTP orang tua/wali
                    <p>sebanyak 3 lembar</p>
                  </div>
                </li>
                <li>
                  <img src="@/assets/img/icon-check.png" alt="" />
                  <div>
                    Photo Copy Kartu Keluarga (KK)
                    <p>sebanyak 3 lembar</p>
                  </div>
                </li>
                <li>
                  <img src="@/assets/img/icon-check.png" alt="" />
                  <div>
                    Photo Copy STL/SKHUN/Ijazah
                    <p>sebanyak 3 lembar</p>
                  </div>
                </li>
                <li>
                  <img src="@/assets/img/icon-check.png" alt="" />
                  Surat Keterangan Sehat dari Fasilitas Kesehatan
                </li>
              </ul>
            </div>
            <div class="col-12 col-lg-5">
              <img
                src="@/assets/img/PAGE2.png"
                width="100%"
                alt=""
                class="mb-3"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- END Syarat Pendaftaran Ulang -->

    <!-- Feature Section Start -->
    <section class="features section-padding">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="section-title">
              <h2>Alur <span>Penyerahan Santri</span></h2>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6">
            <div class="feature-item">
              <div class="icon">
                <fa-icon :icon="['fas', 'first-aid']"></fa-icon>
              </div>
              <h2>1</h2>
              <h3>Checkup / Periksa Kesehatan</h3>
              <p>
                Pemeriksaan kesehatan dari calon peserta didik oleh petugas
                klinik Az-Zainiyah.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="feature-item">
              <div class="icon">
                <fa-icon :icon="['fas', 'file-alt']"></fa-icon>
              </div>
              <h2>2</h2>
              <h3>Konfirmasi Nomor Registrasi</h3>
              <p>
                Menyerahkan Nomor Registrasi dan bukti pendaftaran online kepada
                petugas PSB.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="feature-item">
              <div class="icon">
                <fa-icon :icon="['fas', 'book-reader']"></fa-icon>
              </div>
              <h2>3</h2>
              <h3>Ikrar Santri</h3>
              <p>
                Melakukan Ikrar Santri dan kesediaan mengikuti aturan yang
                ditetapkan oleh Pondok Pesantren Nurul Jadid.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="feature-item">
              <div class="icon">
                <fa-icon :icon="['fas', 'tshirt']"></fa-icon>
              </div>
              <h2>4</h2>
              <h3>Pengambilan Seragam</h3>
              <p>
                Pengambilan seragam sesuai dengan pemilihan ukuran seragam yang
                telah dipilih oleh pendaftar.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="feature-item">
              <div class="icon">
                <fa-icon :icon="['fas', 'handshake']"></fa-icon>
              </div>
              <h2>5</h2>
              <h3>Sowan Pengasuh</h3>
              <p>
                Penyerahan calon peserta didik oleh orangtua / wali kepada
                pengasuh
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="feature-item">
              <div class="icon">
                <fa-icon :icon="['fas', 'person-booth']"></fa-icon>
              </div>
              <h2>6</h2>
              <h3>Asrama Santri</h3>
              <p>
                Santri baru menempati asrama yang telah ditetepkan oleh
                pengurus.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Feature Section End -->

    <section class="fun-facts section-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-5 d-flex">
            <div class="fun-facts-img">
              <img src="@/assets/img/PAGE3.png" alt="fun facts" />
            </div>
          </div>
          <div class="col-lg-6 col-md-7">
            <div class="section-title mb-5">
              <h2>Informasi <span>Pelayanan Pendaftaran</span></h2>
            </div>
            <div class="fun-facts-text">
              <div class="row">
                <div class="col-lg-10">
                  <div id="accordion">
                    <div
                      class="accordion-item"
                      v-for="(item, id) in items"
                      :key="id"
                    >
                      <div
                        class="accordion-header"
                        v-on:click="item.open = !item.open"
                        :class="item.open === false ? 'collapsed' : ''"
                        data-toggle="collapse"
                        data-target="#collapse-01"
                      >
                        <h3>{{ item.title }}</h3>
                      </div>
                      <div
                        class="collapse"
                        :class="item.open === true ? 'show' : ''"
                        id="collapse-01"
                        data-parent="#accordion"
                      >
                        <div class="accordion-body">
                          <p v-html="item.content"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer Section Start -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="footer-col">
              <h3>Pondok Pesantren Nurul Jadid</h3>
              <p>
                Jl. KH. Zaini Mun'im, Dusun Tanjung Lor, Desa Karanganyar,
                <br />Paiton - Probolinggo, <br />
                Jawa Timur 67291.
                <br />Telp. (0888-307-7077)
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="footer-col">
              <h3>Social Pages</h3>
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/pesantrennuruljadid"
                    target="_blank"
                    ><fa-icon
                      :icon="['fab', 'facebook-square']"
                      class="mr-3"
                    />Facebook</a
                  >
                </li>
                <li>
                  <a href="https://twitter.com/ppnuruljadid" target="_blank"
                    ><fa-icon
                      :icon="['fab', 'twitter-square']"
                      class="mr-3"
                    />Twitter</a
                  >
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/pesantrennuruljadid"
                    target="_blank"
                    ><fa-icon
                      :icon="['fab', 'instagram-square']"
                      class="mr-3"
                    />Instagram</a
                  >
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/c/PondokPesantrenNurulJadid"
                    target="_blank"
                    ><fa-icon
                      :icon="['fab', 'youtube-square']"
                      class="mr-3"
                    />Youtube</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 text-right">
            <div class="footer-col text-left">
              <h3>
                <fa-icon
                  style="transform: scaleX(-1)"
                  class="mr-2"
                  :icon="['fas', 'phone']"
                />
                Pusat Layanan Informasi
              </h3>
              <ul>
                <li>
                  <a href="tel:0813-3622-2034"
                    >0813-3622-2034 (Sekretariat Putra)</a
                  >
                </li>
                <li>
                  <a href="tel:0821-4639-3907"
                    >0821-4639-3907 (Sekretariat Putri)</a
                  >
                </li>
                <li>
                  <a href="tel:0852-5880-0137">0852-5880-0137 (Keuangan)</a>
                </li>
                <li>
                  <a href="tel:0822-4633-5137">0822-4633-5137 (Layanan Umum)</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <p class="copyright-text">
              &copy;Copyright © 2019 - {{ year }} - Nurul Jadid
            </p>
          </div>
        </div>
      </div>
    </footer>
    <!-- Footer Section End -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: {
        info1: {
          title: "Pembukaan Pendaftaran & Kantor Layanan:",
          content:
            '<ul class="text-left"><li>Tanggal: <p class="mb-1"><b>1 Maret s.d. 9 Juli 2025</b></p></li><li>Layanan Putra: <p class="mb-1"><b>Kantor Sekretariat Putra</b></p></li><li>Layanan Putri: <p class="mb-1"><b>Kantor Sekretariat Putri</b></p></li></ul>',
          open: true,
        },
        info2: {
          title: "Penerimaan Satu Atap & Verifikasi Berkas:",
          content:
            '<ul class="text-left"><li>Tanggal: <p class="mb-1"><b>5 s.d 9 Juli 2025</b></p></li><li>Tempat Penerimaan: <p class="mb-1"><b>Aula II Pesantren, Lantai 3</b></p></li></ul>',
          open: true,
        },
        info3: {
          title: "Waktu Pelayanan:",
          content:
            '<ul class="text-left"><li>Pagi:<p class="mb-1"><b>08.00 ~ 12.00 WIB</b></p></li><li>Siang:<p class="mb-1"><b>13.00 ~ 16.00 WIB</b></p></li></ul>',
          open: true,
        },
      },
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "../assets/css/landing.scss";
</style>
